import { composeUrlWithParams, parseUrlParams } from '../utils.ts';

/*
  This file defines the route definition for a given microfrontend. It is used as a means to centralise
  route definition, parsing, and composition.

  This example defines a route '/my-route' which has an optional query parameter.
*/

export type RouteParams = { openItems: string[] };

/**
 * Regex use for matching the route.
 */
const routeRegex = /^\/faq\/?$/;

/**
 * A function which can be used to parse the route associated with this microfrontend.
 * The parser should return null, if the route does not match. An empty object if the route
 * matches but no parameters are required and an object containing the parameters if they are present.
 */
const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(routeRegex);

  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);

  return { openItems: [...new Set<string>(searchParams.items)] };
};

/**
 * A function which can be used to compose the routes associated with this microfrontend.
 * This function should accept an parameters and return a composed route. If parameters are
 * optional, this function should accomodate that fact.
 */
const composer = (params?: { openItems?: string[] }): string => {
  return composeUrlWithParams(
    `/faq`,
    params?.openItems?.length ? { items: [...new Set<string>(params?.openItems.filter(Boolean))] } : {},
  );
};

export default {
  parser,
  composer,
};
