import _ from 'lodash';
import { composeUrlWithParams, parseUrlParams, pickAndCast } from '../utils.ts';

type RoutePaginationParams = Partial<
  Record<'pageSort' | 'pageSortDirection' | 'pageNumber' | 'pageSize', string | number>
>;

export type RouteParams =
  | {
      searchGroupId?: string;
      searchNotificationTypes?: string[];
      searchSalesCodes?: string[];
      filterOnlyUnread?: boolean;
    } & RoutePaginationParams;

const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(/^\/communications\/?$/);
  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);

  return {
    ...pickAndCast(searchParams, ['pageNumber', 'pageSize'], (v) => +v),
    ...pickAndCast(searchParams, ['pageSort', 'pageSortDirection', 'searchGroupId'], (v) => `${v}`),
    ...('searchNotificationTypes' in searchParams
      ? {
          searchNotificationTypes: (Array.isArray(searchParams.searchNotificationTypes)
            ? searchParams.searchNotificationTypes
            : [searchParams.searchNotificationTypes]
          )
            .map((nt: string) => nt?.trim())
            .filter(Boolean),
        }
      : {}),
    ...('searchSalesCodes' in searchParams
      ? {
          searchSalesCodes: (Array.isArray(searchParams.searchSalesCodes)
            ? searchParams.searchSalesCodes
            : [searchParams.searchSalesCodes]
          )
            .map((nt: string) => nt?.trim())
            .filter(Boolean),
        }
      : {}),
    ...('filterOnlyUnread' in searchParams
      ? { filterOnlyUnread: !!(searchParams.filterOnlyUnread || '').match(/\s*(y|1|yes|true|on)\s*/i) }
      : {}),
  } as RouteParams;
};

const composer = (params?: RouteParams): string => {
  return composeUrlWithParams(
    `/communications/`,
    params
      ? {
          ..._.pick(params as Record<string, string>, [
            'pageNumber',
            'pageSize',
            'pageSort',
            'pageSortDirection',
            'searchGroupId',
            'filterOnlyUnread',
          ]),
          ...('searchNotificationTypes' in params && params.searchNotificationTypes !== undefined
            ? {
                searchNotificationTypes: (Array.isArray(params.searchNotificationTypes)
                  ? params.searchNotificationTypes
                  : [params.searchNotificationTypes]
                )
                  .map((nt) => nt?.trim())
                  .filter(Boolean) as string[],
              }
            : {}),
          ...('searchSalesCodes' in params && params.searchSalesCodes !== undefined
            ? {
                searchSalesCodes: (Array.isArray(params.searchSalesCodes)
                  ? params.searchSalesCodes
                  : [params.searchSalesCodes]
                )
                  .map((nt) => nt?.trim())
                  .filter(Boolean) as string[],
              }
            : {}),
        }
      : {},
  );
};

export default {
  parser,
  composer,
};
