export type RouteParams = {};

const parser = ({ pathname }: Pick<Location, 'pathname'>): null | RouteParams => {
  const match = pathname.match(/^\/dashboard\/?$/);

  if (!match) {
    return null;
  }

  return {};
};

const composer = (): string => {
  return `/dashboard`;
};

export default {
  parser,
  composer,
};
