import _ from 'lodash';
import { composeUrlWithParams, parseUrlParams, pickAndCast } from '../utils.ts';

type RoutePaginationParams = Partial<
  Record<'pageSort' | 'pageSortDirection' | 'pageNumber' | 'pageSize', string | number>
>;

export type RouteParams =
  | ({
      searchType?: never;
    } & RoutePaginationParams)
  | ({
      searchType: 'SUBSCRIBER_ID';
      searchSubscriberId?: string;
    } & RoutePaginationParams)
  | ({
      searchType: 'POLICY_ATTRIBUTES';
      searchGroupName?: never;
      searchGroupId?: string;
      searchMemberFirstName?: string;
      searchMemberLastName?: string;
    } & RoutePaginationParams)
  | ({
      searchType: 'POLICY_ATTRIBUTES';
      searchGroupId?: never;
      searchGroupName?: string;
      searchMemberFirstName?: string;
      searchMemberLastName?: string;
    } & RoutePaginationParams);

const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(/^\/policies\/?$/);
  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);
  const searchType =
    searchParams.searchType && ['POLICY_ATTRIBUTES', 'SUBSCRIBER_ID'].includes(searchParams.searchType)
      ? searchParams.searchType
      : searchParams.searchSubscriberId
      ? 'SUBSCRIBER_ID'
      : searchParams.searchGroupId ||
        searchParams.searchGroupName ||
        searchParams.searchMemberFirstName ||
        searchParams.searchMemberLastName
      ? 'POLICY_ATTRIBUTES'
      : null;

  const pagination = {
    ...pickAndCast(searchParams, ['pageNumber', 'pageSize'], (v) => +v),
    ...pickAndCast(searchParams, ['pageSort', 'pageSortDirection'], (v) => `${v}`),
  };

  if (searchType === 'SUBSCRIBER_ID') {
    // For searchType=SUBSCRIBER_ID, we only return relevant parameters, not all
    return {
      ...pagination,
      ...pickAndCast(searchParams, ['searchSubscriberId', 'searchType'], (v) => `${v}`),
    } as RouteParams;
  }

  if (searchType === 'POLICY_ATTRIBUTES') {
    // Similarly, for searchType=POLICY_ATTRIBUTES, we only return relevant parameters, not all
    return {
      ...pagination,
      ...pickAndCast(
        searchParams,
        searchParams.searchGroupId
          ? ['searchGroupId', 'searchMemberFirstName', 'searchMemberLastName', 'searchType']
          : ['searchGroupName', 'searchMemberFirstName', 'searchMemberLastName', 'searchType'],
        (v) => `${v}`,
      ),
    } as RouteParams;
  }

  // If it matches no searchType, we only return pagination
  return pagination as RouteParams;
};

const composer = (params?: RouteParams): string => {
  return composeUrlWithParams(
    `/policies/`,
    params
      ? _.pick(Object.fromEntries(Object.entries(params).filter(([, v]) => !!v)) as Record<string, string>, [
          'pageNumber',
          'pageSize',
          'pageSort',
          'pageSortDirection',
          'searchGroupId',
          'searchGroupName',
          'searchMemberFirstName',
          'searchMemberLastName',
          'searchSubscriberId',
          'searchType',
        ])
      : {},
  );
};

export default {
  parser,
  composer,
};
