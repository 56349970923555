/*
  This file defines the route definition for a given microfrontend. It is used as a means to centralise
  route definition, parsing, and composition.

  This example defines a route '/my-route' which has an optional query parameter.
*/

type ContentType = 'terms-of-use' | 'privacy-statement';
export type RouteParams = { type: ContentType | null };

/**
 * Regex use for matching the route.
 */
const routeRegex = /(terms-of-use|privacy-statement)(?:\/?|\/\?.{1,99})$/;

/**
 * A function which can be used to parse the route associated with this microfrontend.
 * The parser should return null, if the route does not match. An empty object if the route
 * matches but no parameters are required and an object containing the parameters if they are present.
 */
const parser = ({ pathname }: Pick<Location, 'pathname'>): null | RouteParams => {
  const match = pathname.match(routeRegex);

  if (match?.[1]) {
    return { type: match[1] as ContentType };
  }

  return null;
};

/**
 * A function which can be used to compose the routes associated with this microfrontend.
 * This function should accept an parameters and return a composed route. If parameters are
 * optional, this function should accomodate that fact.
 */
const composer = (name: ContentType): string => {
  return `/${name}`;
};

export default {
  parser,
  composer,
};
