import getReliableCallback from '@sx/advisergw-runtime-utils/getReliableCallback.ts';
import _ from 'lodash';

import { GTMPayload } from './types.ts';

type Context = { target?: HTMLElement };

// we don't want to wait forever until GTM calls eventCallback (ms)
const EVENT_TIMEOUT = 500;

const redactEmails = (str: string): string => {
  return str.replace(/([a-zA-Z0-9._%+-]{1,256}@[a-zA-Z0-9.-]{1,256}\.[a-zA-Z]{2,64})/g, (match) => {
    return match.toLowerCase().includes('@southerncross') ? '{southernCrossEmail}' : '{email}';
  });
};

const sendToDataLayer = (event: any) => {
  try {
    // GTM may have not loaded yet, but it's smart enough not to redeclare dataLayer, but use it instead
    window.dataLayer = window.dataLayer || [];

    // When we try to send a nested object into GTM, e.g. { foo: { bar: 1, baz: 2 } }, GTM flattens it and sends data to
    // its server as { "foo.bar": 1, "foo.baz": 2 }. The data on the server is merged, resulting in:
    // event1 = { foo: { bar: 1, baz: 2 } }
    // event2 = { foo: { qux: 3 } }
    // are seen in dataLayer as { "foo.bar": 1, "foo.baz": 2, "foo.qux": 3 }, which are merged together
    // where we actually want to distinguish between those 2 objects

    // GTM proposed a solution to clean those objects via:
    // call 1: dataLayer.push({ foo: null }); //  which removes "foo.*" from the server
    // call 2: dataLayer.push({ foo: { qux: 3 } });

    // we don't use merging nested objects, hence:
    const keysToEmpty = Object.entries(event).reduce((acc, [key, value]) => {
      if (value !== null && typeof value === 'object') {
        acc.push(key);
      }
      return acc;
    }, [] as string[]);

    if (keysToEmpty.length > 0) {
      // clean dataLayer's page_details first
      (window as any).dataLayer?.push(Object.fromEntries(keysToEmpty.map((key) => [key, null])));
    }

    // now, let's send data to dataLayer
    window.dataLayer.push?.({
      // now, let's remove empty values from nested objects
      ...Object.entries(event).reduce((ret, [key, val]) => {
        // eslint-disable-next-line no-param-reassign
        ret[key] = keysToEmpty.includes(key)
          ? Object.fromEntries(Object.entries(val as object).filter(([, v]) => !!v || v === false))
          : val;
        return ret;
      }, {} as any),
    });

    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log('[GTM] Collect', event);
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e?.message);
  }
};

const deriveContext = (domEvent?: any): Context | undefined => {
  if (domEvent?.target?.nodeType === Node.ELEMENT_NODE) {
    return {
      target: domEvent.target,
    };
  }
  return undefined;
};

const logEvent = (
  payload: GTMPayload & {
    /**
     * @description This is always called within 500ms! Even if the event isn't logged.
     */
    eventCallback?: () => void;
  },
  domEvent?: any,
): undefined => {
  const context = deriveContext(domEvent);
  try {
    const data = {
      event: payload.event,
      ...('eventModel' in payload ? { eventModel: payload.eventModel } : {}),
      ...(payload.eventCallback
        ? { eventCallback: getReliableCallback(payload.eventCallback, [], EVENT_TIMEOUT), eventTimeout: EVENT_TIMEOUT }
        : {}),
    } as any;

    switch (payload.event) {
      case 'call_to_action': {
        const elementText = payload.elementText || context?.target?.innerText;
        sendToDataLayer({
          ...data,
          ...(elementText ? { 'gtm.elementText': elementText } : {}),
          'gtm.elementId': payload.elementId || context?.target?.id,
        });
        return;
      }
      case 'link_click': {
        const elementText = payload.elementText || context?.target?.innerText;
        sendToDataLayer({
          ...data,
          'gtm.linkType': payload.elementId || context?.target?.id, // For some reasons, we send it as linkType, but later, under the hood, we map it back into elementId
          ...(elementText ? { 'gtm.elementText': elementText } : {}),
          ...(payload.linkUrl ? { 'gtm.elementUrl': redactEmails(payload.linkUrl) } : {}),
          'gtm.referrer': window.location.href,
        });
        return;
      }
      case 'navigation':
        sendToDataLayer({
          ...data,
          'gtm.elementText': payload.text,
          'gtm.navigationType': payload.type,
        });
        return;
      case 'notification':
        sendToDataLayer({
          ...data,
          'gtm.elementText': payload.text,
        });
        return;
      case 'authentication':
        sendToDataLayer({
          ...payload,
          ...data,
        });
        return;
      case 'search':
        sendToDataLayer({
          ...payload,
          ...data,
        });
        return;
      default:
        // eslint-disable-next-line no-console
        console.error(`[GTM] Unhandled event type: ${(payload as any)?.event}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`[GTM] Failed to process event: ${e}`);
  }
};

/**
 * This wrapper always returns a new function
 *
 * @param payload - Either an event object to log OR an event factory function to call
 * @param callback - The original function to wrap with a tracker.
 */
function withTracking<T extends (...args: any[]) => void>(
  payload: GTMPayload | ((...args: Parameters<T>) => GTMPayload),
  callback: T,
): (...args: Parameters<T>) => void;

function withTracking(payload: GTMPayload | (() => GTMPayload)): () => void;
function withTracking<T extends (...args: any[]) => void>(
  payload: GTMPayload | ((...args: Parameters<T>) => GTMPayload),
  callback?: T,
): (...args: Parameters<T>) => void {
  return callback
    ? (...args: any[]) => {
        logEvent(
          {
            ...(typeof payload === 'function' ? payload(...(args as Parameters<typeof payload>)) : payload),
            eventCallback: () => callback(...args),
          },
          args[0],
        );
      }
    : (...args: any[]) => {
        logEvent(typeof payload === 'function' ? payload(...(args as Parameters<typeof payload>)) : payload, args[0]);
      };
}

const Analytics = {
  track: withTracking,
  logEvent,
  logPageView: (payload: { page_referrer: string; page_location: string; page_title: string; page_details: any }) => {
    sendToDataLayer({ ...payload, event: 'page_view' });
  },
};

export default Analytics;
