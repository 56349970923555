// This is provided as a Webpack UMD entry

import Analytics from '@sx/advisergw-analytics';

import routes, { UrlParser } from '../routes.ts';

/**
 * We send custom page_view events
 */

const derivePageDetails = Object.entries(routes)
  .map(([name, { parser }]) => [name, parser] as [string, UrlParser])
  .reduce(
    (prev, [name, parser]) =>
      (location: Location) => {
        const params = prev(location);
        const cur = parser(location);
        if (!cur) {
          return params;
        }
        return {
          ...params,
          ...Object.fromEntries(Object.entries(cur).map(([k, v]) => [`${name}__${k}`, v])),
          page_match: params.page_match ? `${params.page_match},${name}` : name,
        };
      },
    (_location: Location): Record<string, any> => ({}),
  );

const lastKnonwBits: { url?: string } = {
  url: document.referrer ? (document.referrer === window.location.href ? '' : document.referrer) : '',
};

const sendCustomPageViewEvent = () => {
  // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtm
  const payload = {
    page_referrer: lastKnonwBits.url || '',
    page_location: window.location.href,
    page_details: derivePageDetails(window.location),
  };
  lastKnonwBits.url = window.location.href;
  // let's postpone it for a bit to let it populate the new document title
  setTimeout(() => {
    Analytics.logPageView({ ...payload, page_title: document.title });
  }, 50);
};

// let's send the current page:
sendCustomPageViewEvent();

const handlePop = (_ev: PopStateEvent): void => sendCustomPageViewEvent();
window.addEventListener('popstate', handlePop);
