// This is provided as a Webpack UMD entry

import StackTrace from 'stacktrace-js';
import { ConfigurationManager } from '@sx/advisergw-config-manager';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * Setting up Application Insights (if configured)
 */

if (ConfigurationManager.hasValue('AI_CONNECTION_STRING')) {
  const ai = new ApplicationInsights({
    config: {
      connectionString: ConfigurationManager.get('AI_CONNECTION_STRING'),
      addRequestContext: () => ({
        environment: ConfigurationManager.get('ENV', ''),
      }),

      // Related to tracking api and other calls via xhr and fetch
      disableAjaxTracking: false,
      enableAjaxErrorStatusText: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableXhr: false,
      disableFetchTracking: false,

      // to make sure we're not missing anything
      disableDataLossAnalysis: false,
      disableFlushOnBeforeUnload: false,

      // To catch JS errors/unhandled exceptions/rejected promises
      disableExceptionTracking: false,
      enableUnhandledPromiseRejectionTracking: true,

      loggingLevelConsole: 2,
      loggingLevelTelemetry: 2,
      enableDebug: true,

      // this should track navigation via history api push calls as page views
      enableAutoRouteTracking: true,

      isBeaconApiDisabled: !('sendBeacon' in navigator && typeof navigator.sendBeacon === 'function'),
    },
  });
  ai.loadAppInsights();

  // let's make it available as if it's declared by the CDN's version
  window.appInsights = ai;

  // to track this page
  ai.trackPageView();

  // unfortunately, we have a habit of logging the same error multiple times (it's actually really hard to track in the code)
  // hence, we deduplicate them here
  const last5knownErrors: any[] = [];

  // let's override all console.warn and console.error calls as Application Insights doesn't do that automatically
  // this must be loaded before anything else on the page, otherwise the place where console.error is called can be reported wrongly
  // this expect window.appInsights to be declared
  (['warn', 'error'] as Extract<keyof Console, 'error' | 'warn'>[]).forEach((name) => {
    // eslint-disable-next-line no-console
    const origMethod = console[name];
    // eslint-disable-next-line no-console
    console[name] = (...args: any[]) => {
      // let's find where it was called:
      const stackTrace = StackTrace.getSync({ offline: true });
      if (stackTrace[0]?.functionName?.match(/^console\./)) {
        // sometimes StackTrace returns the first entry like "at console.<computed> ..."
        // let's remove it
        stackTrace.shift();
      }

      const at = ` ${stackTrace[0].source?.trim() || ''}`;

      // eslint-disable-next-line default-case
      switch (name) {
        case 'error': {
          const error = args.find(
            (arg) =>
              arg != null &&
              typeof arg === 'object' &&
              (('stack' in arg && typeof arg.stack === 'string') ||
                ('stacktrace' in arg && typeof arg.stacktrace === 'string')),
          ) as Error | undefined;
          if (error) {
            if (last5knownErrors.indexOf(error) === -1) {
              if (last5knownErrors.length > 5) {
                last5knownErrors.shift();
              }
              last5knownErrors.push(error);
              window.appInsights?.trackException({
                exception: error,
                severityLevel: 3, // eSeverityLevel.Error,
              });
            }
          } else {
            window.appInsights?.trackException({
              exception: {
                message: `console.error${at}: ${args[0]}`,
                url: stackTrace[0].fileName || '',
                lineNumber: stackTrace[0].lineNumber || -1,
                columnNumber: stackTrace[0].columnNumber || -1,
                error: new Error(args[0]),
              },
              severityLevel: 3, // eSeverityLevel.Error,
            });
          }
          break;
        }
        case 'warn': {
          window.appInsights?.trackTrace({
            message: `console.warn${at}: ${args[0]}`,
            severityLevel: 2, // eSeverityLevel.Warning,
          });
        }
      }

      origMethod.apply(console, args);
    };
  });
}
