/**
 * Depending on the environment, we get one of self, windows (even frames), global, globalThis
 * P.S. Babel polyfills global
 */
const globalObject =
  // @ts-ignore
  // eslint-disable-next-line no-restricted-globals
  typeof globalThis !== 'undefined' ? globalThis : typeof self !== 'undefined' ? self : window || global;

export const getGlobalObject = (): typeof window | typeof globalThis => globalObject;

export default getGlobalObject;
