import _ from 'lodash';
import { composeUrlWithParams, parseUrlParams, pickAndCast } from '../utils.ts';

type RoutePaginationParams = Partial<
  Record<'pageSort' | 'pageSortDirection' | 'pageNumber' | 'pageSize', string | number>
>;

export type RouteParams =
  | ({
      searchType?: never;
    } & RoutePaginationParams)
  | ({
      searchType: 'GROUP_CODE';
      searchGroupCode: string;
    } & RoutePaginationParams)
  | ({
      searchType: 'GROUP_NAME';
      searchGroupName: string;
    } & RoutePaginationParams);

const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(/^\/groups(?:\/?)$/);

  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);

  const searchType =
    searchParams.searchType && ['GROUP_CODE', 'GROUP_NAME'].includes(searchParams.searchType)
      ? searchParams.searchType
      : searchParams.searchGroupName
      ? 'GROUP_NAME'
      : 'GROUP_CODE';

  const pagination = {
    ...pickAndCast(searchParams, ['pageNumber', 'pageSize'], (v) => +v),
    ...pickAndCast(searchParams, ['pageSort', 'pageSortDirection'], (v) => `${v}`),
  };

  if (searchType === 'GROUP_CODE') {
    // For searchType=GROUP_CODE, we only return relevant parameters, not all
    return {
      ...pagination,
      ...pickAndCast(searchParams, ['searchGroupCode', 'searchType'], (v) => `${v}`),
    } as RouteParams;
  }

  if (searchType === 'GROUP_NAME') {
    // Similarly, for searchType=GROUP_NAME, we only return relevant parameters, not all
    return {
      ...pagination,
      ...pickAndCast(
        searchParams,
        searchParams.searchGroupId ? ['searchGroupId', 'searchType'] : ['searchGroupName', 'searchType'],
        (v) => `${v}`,
      ),
    } as RouteParams;
  }
  // If it matches no searchType, we only return pagination
  return pagination as RouteParams;
};

const composer = (params?: RouteParams): string => {
  return composeUrlWithParams('/groups/', {
    ...(params?.searchType === 'GROUP_CODE'
      ? { searchType: 'GROUP_CODE', searchGroupCode: params.searchGroupCode }
      : params?.searchType === 'GROUP_NAME'
      ? { searchType: 'GROUP_NAME', searchGroupName: params.searchGroupName }
      : {}),
    ..._.pick(params as Record<string, string>, ['pageNumber', 'pageSize', 'pageSort', 'pageSortDirection']),
  });
};

export default {
  parser,
  composer,
};
