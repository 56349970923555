import { parsers } from '@sx/advisergw-url-manager';
import { ActivityFn } from 'single-spa';

type RouteNames = keyof typeof parsers;
export type UrlParser = (location: Location) => Record<string, any> | null;
type Routes = {
  [route in RouteNames]: { activeWhen: ActivityFn; parser: UrlParser };
};

const routes: Readonly<Routes> = (Object.keys(parsers) as RouteNames[]).reduce((acc, cur) => {
  return {
    ...acc,
    [cur]: { parser: parsers[cur], activeWhen: (location: Location) => parsers[cur](location) !== null },
  };
}, {} as Routes);

export default routes;
