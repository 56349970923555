import qs from 'qs';
import _ from 'lodash';

type Scalar = undefined | null | boolean | number | string;

const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);

export const composeUrlWithParams = (
  path: string,
  params: Scalar[] | Record<string, Scalar | Scalar[] | Record<string, Scalar | Scalar[]>>,
): string =>
  [
    path.replace(/\??$/, ''),
    qs.stringify(Object.fromEntries(Object.entries(params).filter(([, val]) => val !== undefined)), {
      strictNullHandling: true,
      arrayFormat: 'brackets',
      allowDots: false,
      allowEmptyArrays: true,
      encodeDotInKeys: false,
      sort: alphabeticalSort,
    }),
  ]
    .filter(Boolean)
    .join('?');

export const parseUrlParams = (search: string): any => {
  // TODO: Address the case where "foo[]" results in "foo: [ null ]"
  return qs.parse(search, {
    plainObjects: true,
    arrayLimit: 99,
    comma: false,
    ignoreQueryPrefix: true,
    allowDots: false,
    allowEmptyArrays: true,
    duplicates: 'combine',
    strictNullHandling: true,
    depth: 3,
  });
};

export const pickAndCast = <I extends object, U extends keyof I, T extends any>(
  input: I,
  keys: U[],
  caster: (val: I[U], key: U) => T,
) => {
  return Object.fromEntries(
    Object.entries(_.pick(input, keys)).map(([k, v]) => [k, caster(v as I[U], k as U)]),
  ) as Partial<Record<U, T>>;
};
