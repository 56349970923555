import getGlobalObject from '@sx/advisergw-runtime-utils/getGlobalObject.ts';

type GlobalObbject = {
  RUNTIME_VARIABLES: { [key: string]: string | undefined };
};

const globalObject = getGlobalObject() as any as GlobalObbject;

const getVal = <T>(key: string, defaultVal?: T) => {
  const val = globalObject.RUNTIME_VARIABLES?.[key]?.trim();

  if (val === undefined && defaultVal === undefined) {
    throw new Error(`${key} is missing from the config and no default value was provided.`);
  }

  return val;
};

export class ConfigurationManager {
  /**
   * @deprecated Please consider using ConfigurationManager.readConfiguration and preload configuration
   *
   * Get the value from the config.
   * You should use the hasValue function to check if a key exists before accessing it.
   *
   * @param key The key for the value you would like to retrieve
   * @param defaultVal (Optional) A value to default to if the value is empty
   * @returns The value of the config variable as a string
   */
  public static get(key: string, defaultVal?: string) {
    const val = getVal(key, defaultVal);

    return val !== undefined ? val : defaultVal!;
  }
  /**
   * Get a numeric value from the config.
   * You should use the hasValue function to check if a key exists before accessing it.
   *
   * @param key The key for the value you would like to retrieve
   * @param defaultVal (Optional) A value to default to if the value is empty
   * @returns The config value as a number
   */
  public static getAsNumber(key: string, defaultVal?: number) {
    const val = getVal(key, defaultVal);

    if (val === undefined) {
      return defaultVal!;
    }

    const valAsFloat = Number.parseFloat(val);

    if (Number.isNaN(valAsFloat) && defaultVal === undefined) {
      throw new Error(`${key} is malformed and no default value was provided.`);
    }

    return Number.isNaN(valAsFloat) ? defaultVal! : valAsFloat;
  }

  /**
   * Get a boolean value from the config.
   * You should use the hasValue function to check if a key exists before accessing it.
   *
   * @param key The key for the value you would like to retrieve
   * @param defaultVal (Optional) A value to default to if the value is empty
   * @returns The config value as a boolean
   */
  public static getAsBoolean(key: string, defaultVal?: boolean) {
    const val = getVal(key, defaultVal);

    if (val === undefined) {
      return defaultVal!;
    }

    const lowercaseVal = val.toLowerCase();

    if (lowercaseVal === 'true') {
      return true;
    }

    if (lowercaseVal === 'false') {
      return false;
    }

    if (defaultVal === undefined) {
      throw new Error(`${key} is malformed and no default value was provided.`);
    }

    return defaultVal!;
  }

  /**
   * @deprecated Please consider using ConfigurationManager.readConfiguration
   */
  public static validateConfiguration(configDescription: Record<string, 'boolean' | 'string' | 'number'>) {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const [name, type] of Object.entries(configDescription)) {
        switch (type) {
          case 'boolean':
            ConfigurationManager.getAsBoolean(name);
            break;
          case 'number':
            ConfigurationManager.getAsNumber(name);
            break;
          default:
            ConfigurationManager.get(name);
        }
      }
    } catch (e) {
      throw new Error(`Configuration Validation Failed: ${(e as Error)?.message}`);
    }
  }

  /**
   * IMPORTANT!!! Consider reading the configuration once and keeping the values in JS memory to implement a more secure/robust solution.
   */
  public static readConfiguration<T extends Record<string, 'boolean' | 'string' | 'json' | 'number'>>(
    configDescription: T,
  ): {
    [K in keyof T]: 'boolean' extends T[K]
      ? boolean
      : 'number' extends T[K]
      ? number
      : 'string' extends T[K]
      ? string
      : unknown;
  } {
    try {
      const res = {} as any;
      // eslint-disable-next-line no-restricted-syntax
      for (const [name, type] of Object.entries(configDescription)) {
        switch (type) {
          case 'boolean':
            res[name] = ConfigurationManager.getAsBoolean(name);
            break;
          case 'number':
            res[name] = ConfigurationManager.getAsNumber(name);
            break;
          case 'json':
            res[name] = JSON.parse(ConfigurationManager.get(name));
            break;
          default:
            res[name] = ConfigurationManager.get(name);
        }
      }
      return res;
    } catch (e) {
      throw new Error(`Configuration Validation Failed: ${(e as Error)?.message}`);
    }
  }

  /**
   * Checks to see where a key exists within the config.
   *
   * @param key The key for the value you would like to retrieve
   * @returns A boolean indicating whether the key exists in the config
   */
  public static hasValue(key: string) {
    return globalObject.RUNTIME_VARIABLES?.[key] !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
}

/**
 * @deprecated Please use ConfigurationManager.readConfiguration directly
 */
const useConfigManager = () => ConfigurationManager;

export default useConfigManager;
