// In some cases, we need to make sure that a callback is always called, e.g. eventCallback we provide to GTM may not be
// called at all or called with an unacceptable delay.
//
// However, our logic may depend on the fact that the callback is always invoked, hence we maintain it this way
// we wait until the callback is called for TIMEOUT. if it's not, we call it forcefully and remember that, so that we
// can avoid double calls

const TIMEOUT_MS = 500;

const pendingCalls = new WeakSet<Function>();
const getReliableCallback = (cb: Function, args: any[], timeout: number = TIMEOUT_MS): (() => Function) => {
  pendingCalls.add(cb);
  const guaranteedCallback = () => {
    if (!pendingCalls.has(cb)) {
      return;
    }
    pendingCalls.delete(cb);
    return cb(...args);
  };
  setTimeout(guaranteedCallback, timeout);
  return guaranteedCallback;
};

export default getReliableCallback;
