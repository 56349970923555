import MeshComm from 'mesh-comm';
import { ConfigurationManager } from '@sx/advisergw-config-manager';
import { FeatureFlags } from '@sx/advisergw-feature-flags';

if (__DEV__) {
  MeshComm.logger.enable();
}

const bus = MeshComm('SxFeatureFlagBus');

const config = (() => {
  try {
    const { FEATURE_FLAGS, FEATURE_ENDPOINT } = ConfigurationManager.readConfiguration({
      FEATURE_FLAGS: 'json',
      FEATURE_ENDPOINT: 'string',
    });
    Object.freeze(FEATURE_FLAGS);
    return {
      defaultFeatureFlags: (FEATURE_FLAGS || {}) as FeatureFlags,
      endpoint: FEATURE_ENDPOINT,
      context: {},
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      defaultFeatureFlags: {} as FeatureFlags,
      endpoint: null,
      context: {},
    };
  }
})();

let abortController: AbortController | null = null;

const parseValue = (val: string): boolean => !!val.match(/^[\s'"]*(yes|y|on|1|true)[\s'"]*$/i);

const reload = () => {
  if (!config.endpoint) {
    return;
  }
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  fetch(config.endpoint, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({ context: config.context }),
    signal: abortController!.signal,
  })
    .then((res) => res.json())
    .then((res) => {
      config.defaultFeatureFlags = {
        ...config.defaultFeatureFlags,
        ...(res.flags
          ? Object.fromEntries(Object.entries(res.flags).map(([key, val]) => [key, parseValue(val + '')]))
          : {}),
      };
      Object.freeze(config.defaultFeatureFlags);
      bus.send('/updates', { flags: config.defaultFeatureFlags });
    })
    .catch((e) => {
      if (e.name.indexOf('AbortError') < 0) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
};

bus.add(
  '/values',
  () => {
    return {
      flags: config.defaultFeatureFlags,
    };
  },
  { exclusive: true },
);

bus.add(
  '/context',
  (msg) => {
    config.context = { ...config.context, ...msg.context };
    reload();
  },
  { exclusive: true },
);

reload();
