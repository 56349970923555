import { composeUrlWithParams, parseUrlParams } from '../utils.ts';

export type RouteParams =
  | { action: 'post-log-out' | 'post-log-in' | 'log-in-migrate' }
  | { action: 'log-in'; redirectUrl?: string; force?: boolean }
  | { action: 'change-password' | 'log-out' | 'session-expired' | 'session-ended'; redirectUrl?: string }
  | {
      action: 'error';
      error?: string;
      retry?: 'log-in' | 'log-in-migrate' | 'change-password';
      redirectUrl?: string;
    };

/**
 * Regex use for matching the route.
 */
const routeRegex = /^\/(auth-error|auth|post-log-in|post-log-out|session-expired|session-ended)(|\/|\/.+)$/;

const castAsBoolean = (val: string | null): boolean => (val ? !!val.match(/^\s*(1|yes|y|true)\s*$/i) : false);

/**
 * A function which can be used to parse the route associated with this microfrontend.
 * The parser should return null, if the route does not match. An empty object if the route
 * matches but no parameters are required and an object containing the parameters if they are present.
 */
const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(routeRegex);

  if (!match) {
    return null;
  }

  if (match[1] === 'auth-error') {
    try {
      const searchParams = parseUrlParams(new URL(href).search);
      const retry = (
        ['log-in', 'log-in-migrate', 'change-password'].includes(searchParams.retry?.toLowerCase() || '')
          ? searchParams.retry?.toLowerCase()
          : null
      ) as 'log-in' | 'log-in-migrate' | 'change-password' | undefined | null;
      return {
        action: 'error',
        error: searchParams.error || undefined,
        redirectUrl: searchParams.redirectUrl || undefined,
        ...(retry ? { retry } : {}),
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return null;
    }
  }

  if (match[1] === 'auth') {
    try {
      const searchParams = parseUrlParams(new URL(href).search);
      const action = searchParams.action;
      if (!action || !['log-in', 'change-password', 'log-out', 'log-in-migrate'].includes(action)) {
        return null;
      }
      if (action === 'log-in-migrate') {
        return { action };
      }
      return {
        action: action as 'log-in' | 'change-password' | 'log-out',
        redirectUrl: searchParams.redirectUrl || undefined,
        ...(action === 'log-in' ? { force: castAsBoolean(searchParams.force) } : {}),
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return null;
    }
  }

  if (['session-expired', 'session-ended'].includes(match[1])) {
    try {
      const searchParams = parseUrlParams(new URL(href).search);
      return {
        action: match[1] as 'session-expired' | 'session-ended',
        redirectUrl: searchParams.redirectUrl || undefined,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return null;
    }
  }

  if (['post-log-in', 'post-log-out'].includes(match[1])) {
    return { action: match[1] as 'post-log-in' | 'post-log-out' };
  }

  return null;
};

/**
 * A function which can be used to compose the routes associated with this microfrontend.
 * This function should accept parameters and return a composed route. If parameters are
 * optional, this function should accommodate that fact.
 */

type ComposerRouteParams =
  | {
      action: 'post-log-out' | 'post-log-in' | 'log-in-migrate';
    }
  | {
      action: 'change-password' | 'log-out' | 'session-expired' | 'session-ended';
      force?: never;
      redirectUrl: string;
    }
  | {
      action: 'log-in';
      force?: boolean;
      redirectUrl: string;
    }
  | {
      action: 'error';
      error?: string;
      retry?: never;
    }
  | {
      action: 'error';
      error?: string;
      retry: 'log-in' | 'log-in-migrate' | 'change-password';
      redirectUrl?: string;
    };

const composer = (params: ComposerRouteParams) => {
  // eslint-disable-next-line default-case
  switch (params.action) {
    case 'error':
      return composeUrlWithParams(
        `/auth-error`,
        params.retry
          ? {
              error: params?.error,
              redirectUrl: params?.redirectUrl,
              retry: params?.retry,
            }
          : {
              error: params?.error,
            },
      );

    case 'log-in':
      return composeUrlWithParams(`/auth`, {
        action: params.action,
        force: params?.force ? 'true' : undefined,
        redirectUrl: params?.redirectUrl,
      });

    case 'change-password':
    case 'log-out':
      return composeUrlWithParams(`/auth`, {
        action: params.action,
        redirectUrl: params?.redirectUrl,
      });

    case 'log-in-migrate':
      return composeUrlWithParams(`/auth`, {
        action: params.action,
      });
    case 'post-log-in':
      return '/post-log-in';
    case 'post-log-out':
      return '/post-log-out';
    case 'session-expired':
    case 'session-ended':
      return composeUrlWithParams(`/${params.action}`, {
        redirectUrl: params?.redirectUrl,
      });
  }
};

export default {
  parser,
  composer,
};
